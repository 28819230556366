<template>
  <invoice-form
    :invoice-type-code="701"
    :movement-type-code="9"
    hide-movement-type-select
  />
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceForm from "@/modules/invoices/views/Form.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoiceForm },
})
export default class ReceiptForm extends Mixins(PaginateProviderMixin) {}
</script>
